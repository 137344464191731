import {Container,Row,Col} from "react-bootstrap";
export const Education= () =>{
    return(
        <section className = "education" id = "educations">
            <div className="education-section">
                <h1 className="education-heading">Education</h1>
                <Col className="education-container">
                <div className="education-card">
                    <h1 className="education">University of Michigan</h1>
                    <h2>
                        Ann Arbor, Michigan <br />
                        Major: Computer Science <br />
                        Courses: Data Structures and Algorithms, Foundation to Computer Science, Discrete Math, Intro to Computer Organization, Web and System Designs, Intro to Computer Security
                    </h2>
                    </div>

                    <div className="education-card">
                        <h1 className="education">Institue for Study Abroad</h1>
                        <h2>
                            Prague, Czech Republic <br />
                            Courses: Database Management Systems, Intro to Machine Learning
                        </h2>
                    </div>
                   
                </Col>
            </div>
        </section>
    )
};